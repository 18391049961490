import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
const i18nConfig = {
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
};
void i18n.use(initReactI18next).init(i18nConfig).catch((error) => {
    throw new Error(`i18n initialization failed: ${error}`);
});
export default i18n;
