import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import InstaCard from '../InstaCard/InstaCard';
import InstaModal from '../InstaModal/InstaModal';
import setupFlickity from '../../../../js/utils/setupFlickity';
import 'flickity/css/flickity.css';
import './Carousel.scss';
const Carousel = ({ data }) => {
    const [selectedInsta, setSelectedInsta] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const flickityOption = {
        pageDots: false,
        groupCells: true,
        freeScroll: true,
        watchCSS: true,
        draggable: isMobile,
        cellAlign: 'left',
    };
    const handleInstaCardClick = (insta, index) => {
        setSelectedInsta(insta);
        setSelectedIndex(index);
    };
    const handleCloseModal = () => {
        setSelectedInsta(null);
        setSelectedIndex(null);
    };
    const handleNextInsta = () => {
        if (selectedIndex !== null) {
            const nextIndex = (selectedIndex + 1) % data.length;
            setSelectedInsta(data[nextIndex]);
            setSelectedIndex(nextIndex);
        }
    };
    const handlePreviousInsta = () => {
        if (selectedIndex !== null) {
            const prevIndex = (selectedIndex - 1 + data.length) % data.length;
            setSelectedInsta(data[prevIndex]);
            setSelectedIndex(prevIndex);
        }
    };
    useEffect(() => {
        const flickityElement = document.querySelector('.ds-instaWidgetCarousel');
        if (flickityElement) {
            setupFlickity('.ds-instaWidgetCarousel', flickityOption);
        }
    }, [isMobile]);
    return (_jsxs("div", { className: "ds-instaWidgetCarousel", children: [_jsx("div", { className: "ds-flickityMobileSwipeCards", children: _jsx("div", { className: "carousel", children: data.map((insta, index) => (_jsx(InstaCard, { insta: insta, onClick: () => handleInstaCardClick(insta, index) }, insta.id))) }) }), selectedInsta && (_jsx(InstaModal, { insta: selectedInsta, onClose: handleCloseModal, onNext: handleNextInsta, onPrev: handlePreviousInsta, showInstaModal: !!selectedInsta, isMobile: isMobile }))] }));
};
export default Carousel;
